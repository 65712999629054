define("api/models/auth-method", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "api/generated/models/auth-method", "@ember/object/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _authMethod, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.options = _exports.TYPES_AUTH_METHOD = _exports.TYPE_AUTH_METHOD_LDAP = _exports.TYPE_AUTH_METHOD_OIDC = _exports.TYPE_AUTH_METHOD_PASSWORD = void 0;

  var _dec, _class, _descriptor;

  const TYPE_AUTH_METHOD_PASSWORD = 'password';
  _exports.TYPE_AUTH_METHOD_PASSWORD = TYPE_AUTH_METHOD_PASSWORD;
  const TYPE_AUTH_METHOD_OIDC = 'oidc';
  _exports.TYPE_AUTH_METHOD_OIDC = TYPE_AUTH_METHOD_OIDC;
  const TYPE_AUTH_METHOD_LDAP = 'ldap';
  _exports.TYPE_AUTH_METHOD_LDAP = TYPE_AUTH_METHOD_LDAP;
  const TYPES_AUTH_METHOD = Object.freeze([TYPE_AUTH_METHOD_PASSWORD, TYPE_AUTH_METHOD_OIDC, TYPE_AUTH_METHOD_LDAP]);
  /**
   * Enum options per auth method type and field.
   */

  _exports.TYPES_AUTH_METHOD = TYPES_AUTH_METHOD;
  const options = {
    oidc: {
      signing_algorithms: ['RS256', 'RS384', 'RS512', 'ES256', 'ES384', 'ES512', 'PS256', 'PS384', 'PS512', 'EdDSA'],
      state: ['inactive', 'active-private', 'active-public'],
      account_claim_maps: {
        to: ['sub', 'name', 'email']
      }
    }
  };
  _exports.options = options;
  let AuthMethodModel = (_dec = (0, _computed.equal)('is_primary', true), (_class = class AuthMethodModel extends _authMethod.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "isPrimary", _descriptor, this);
      (0, _defineProperty2.default)(this, "options", options);
    }

    /**
     * @type {boolean}
     */
    get isPassword() {
      return this.type === TYPE_AUTH_METHOD_PASSWORD;
    }
    /**
     * @type {boolean}
     */


    get isOIDC() {
      return this.type === TYPE_AUTH_METHOD_OIDC;
    }
    /**
     * @type {boolean}
     */


    get isLDAP() {
      return this.type === TYPE_AUTH_METHOD_LDAP;
    }
    /**
     * @type {boolean}
     */


    get isUnknown() {
      return !TYPES_AUTH_METHOD.includes(this.type);
    }
    /**
     * @type {boolean}
     */


    get isInactive() {
      return this.state === 'inactive';
    }
    /**
     * @type {boolean}
     */


    get isPrivate() {
      return this.state === 'active-private';
    }
    /**
     * @type {boolean}
     */


    get isPublic() {
      return this.state === 'active-public';
    } // =methods

    /**
     * Change the active and visibility state of an OIDC auth method
     * given its ID.
     * For OIDC auth methods only.
     * @param {[string]} state
     * @param {object} options
     * @param {object} options.adapterOptions
     * @return {Promise}
     */


    changeState(state) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        adapterOptions: {}
      };
      const defaultAdapterOptions = {
        method: 'change-state',
        state
      };
      return this.save({ ...options,
        adapterOptions: { ...defaultAdapterOptions,
          ...options.adapterOptions
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isPrimary", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AuthMethodModel;
});