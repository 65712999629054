define("ember-cli-clipboard/helpers/is-clipboard-supported", ["exports", "@ember/component/helper", "clipboard", "@ember/application"], function (_exports, _helper, _clipboard, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IsClipboardSupportedHelper extends _helper.default {
    constructor() {
      super(...arguments);
      const service = (0, _application.getOwner)(this).lookup('service:fastboot');
      this.isFastBoot = service ? service.isFastBoot : false;
    }

    compute(_ref) {
      let [action] = _ref;
      const {
        isFastBoot
      } = this;
      return isFastBoot ? false : _clipboard.default.isSupported(action);
    }

  }

  _exports.default = IsClipboardSupportedHelper;
});